import React from 'react';
import Box from '@mui/material/Box';
import SideMenu from './menu/SideMenu';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const Header = () => {
    return (
        <Box
            sx={{
                bgcolor: 'secondary.main',
                color: 'secondary.contrastText',
                minHeight: '60px',
                padding: '15px',
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: '1',
                '& > div': {
                    height: '100%'
                }
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                <Box
                    sx={{
                        '& > a': {
                            color: '#fff',
                            fontSize: {
                                xs: 'smFontSize',
                                sm: 'xlFontSize'
                            },
                            fontWeight: 'bold',
                            marginBottom: {
                                xs: '10px',
                                sm: '0'
                            },
                            textDecoration: 'none',
                            textUnderlineOffset: '5px'
                        },
                        '& > a:hover': {
                            textDecoration: 'underline'
                        }
                    }}
                >
                    <SideMenu />
                </Box>
                <Box
                    sx={{
                        color: '#fff',
                        fontSize: {
                            xs: 'smFontSize',
                            sm: 'xlFontSize'
                        },
                        textDecoration: 'none',
                        fontWeight: 'bold'
                    }}
                >
                    <a
                        style={{
                            alignItems: 'center',
                            color: '#fff',
                            display: 'flex',
                            textDecoration: 'none',
                            textUnderlineOffset: '5px'
                        }}
                        href="https://besmettingenvandaag.m-pages.com/FPuCMu/besmettingen-vandaag-subscriber-form" target="_blank" rel="noreferrer">
                            <Box sx={{textUnderlineOffset: '5px', '&:hover': {textDecoration: 'underline'}}}>Blijf op de hoogte</Box>
                            <MailOutlineIcon sx={{marginLeft: '10px'}} />
                    </a>
                </Box>
            </Box>
        </Box>
    )
};

export default Header;