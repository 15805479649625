import React from 'react';
import Box from '@mui/material/Box';
import Header from '../header/Header';
import Footer from '../footer/Footer';
import SEO from '../SEO';

const Layout = (props) => {
    const { children, location } = props;

    if (location.pathname=== '/offline-plugin-app-shell-fallback/') return null;

    return (
        <Box
            sx={{
                alignItems: 'center',
                bgcolor: 'primary.main',
                color: 'primary.contrastText',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                overflowY: 'scroll',
                padding: '60px 0 50px 0',
                width: '100%',
                '&::-webkit-scrollbar' :{
                    display: 'none'
                }
            }}
        >
            <SEO />
            <Header {...props} />
            {children}
            <Footer />
        </Box>
    );
};

export default Layout;