import React, { useState } from 'react';
import { Box, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from './MenuItem';

const SideMenu = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const MenuItems = () => {
        const items = {
            'home': {name: 'Home', path: '/'},
            'provinces': {name: 'Besmettingen per provincie', path: '/provincies'},
            'data': {name: 'Cijferverantwoording', path: '/cijferverantwoording'}
        };

        return (
            <Box
                sx={{
                    bgcolor: 'secondary.main',
                    height: '100%',
                    width: {xs: '80vw', md: '20vw'}
                }}
            >
                {Object.keys(items).map((item, i) => (
                    <MenuItem toggleMenu={toggleMenu} itemData={items[item]} />
                ))}
            </Box>
        )
    }

    return (
        <Box>
            <MenuIcon fontSize="large" onClick={toggleMenu} />
            <Drawer
                open={menuOpen}
                onClose={toggleMenu}
            >
                <MenuItems />
            </Drawer>
        </Box>
    )
}

export default SideMenu;