import React from 'react';
import { createTheme } from '@mui/material/styles';

const CustomTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1300,
            xl: 1700,
        },
    },
    spacing: [0, 5, 10, 15, 20, 40, 50],
    palette: {
        primary: {
            main: '#33aaff',
            contrastText: '#fff',
            dark: '#413d44'
        },
        secondary: {
            main: '#fed000',
            contrastText: '#fff',
            dark: '#e4bb00'
        }
    },
    typography: {
        xsFontSize: '12px',
        smFontSize: '14px',
        mdFontSize: '16px',
        lFontSize: '18px',
        xlFontSize: '20px'

        // fontFamily: [
        //     '"QuickSand Regular"',
        //     'Roboto',
        //     '"Helvetica Neue"',
        //     'Arial',
        //     'sans-serif',
        //     'Sequel',
        // ].join(','),
        // h1: {
        //     color: '#fff',
        //     fontFamily: 'Sequel',
        //     fontSize: '10px',
        //     textShadow: '0 0 1px transparent, 0 1px 2px rgb(0 0 0 / 80%)',
        // },
    }
});

export default CustomTheme;
