import React from 'react';
import { ThemeProvider,  } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import '../../styles/global.css';
import CustomTheme from '../CustomTheme';

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={CustomTheme}>
        <CssBaseline/>
        {element}
    </ThemeProvider>
);