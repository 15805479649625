import React from 'react';
import Box from '@mui/material/Box';

const Footer = () => {
    return (
            <Box
                sx={{
                    alignItems: 'center',
                    bgcolor: 'primary.dark',
                    bottom: 0,
                    color: 'secondary.contrastText',
                    display: 'flex',
                    fontSize: 'mdFontSize',
                    minHeight: '50px',
                    padding: '15px',
                    justifyContent: 'center',
                    position: 'absolute',
                    width: '100%',
                    zIndex: '1'
                }}
            >
                De dagelijkse coronabesmettingen zodra deze beschikbaar zijn
            </Box>
    )
};

export default Footer;