import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const JsonLd = ({ children }) => {
    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(children)}</script>
        </Helmet>
    )
}

const SEO = () => {
    const seo = {
        title: 'Coronabesmettingen van vandaag',
        description: 'Bekijk hier de dagelijkse landelijke coronabesmettingen van vandaag. Geüpdatet zodra het RIVM de cijfers publiceert.'
    };

    const infectionsData = useStaticQuery(graphql`
        query {
            infectionsData {
                lastModified
            }
        }
    `);

    const lastModified = new Date(infectionsData.infectionsData.lastModified).toISOString();

    return (
        <Helmet title={seo.title}>
            <meta name="description" content={seo.description} />

            <JsonLd>
                {{
                    '@context': 'https://schema.org',
                    '@type': 'NewsArticle',
                    mainEntityOfPage: 'https://besmettingenvandaag.nl/',
                    url: 'https://besmettingenvandaag.nl/',
                    headline: 'Coronabesmettingen van vandaag',
                    name: 'Coronabesmettingen van vandaag',
                    datePublished: '24-01-2022',
                    dateModified: `$(lastModified)`,
                    countryOfOrigin: 'NL'
                }}
            </JsonLd>
        </Helmet>
    );
};

export default SEO;