import React from 'react';
import { Box } from '@mui/material';
import { Link } from 'gatsby';

const MenuItem = (props) => {
    const {name, path} = props.itemData;

    return (
        <Box
            sx={{
                lineHeight: 'normal',

                '& > a': {
                    color: 'secondary.contrastText',
                    display: 'block',
                    fontSize: 'lFontSize',
                    px: 4,
                    py: 3,
                    textDecoration: 'none',
                    width: '100%'
                },

                '&:hover': {
                    bgcolor: 'secondary.dark'
                }
            }}
        >
            <Link onClick={() => props.toggleMenu()} to={path}>{name}</Link>
        </Box>
    )
}

export default MenuItem;